main {
	h2.page-subtitle {
		margin-block: 0;
	}
}

.title-and-icon {
	display: flex;
	justify-content: space-between;
	align-items: center;

	i {
		color: var(--text-secondary);
		margin-inline-start: 1em;
	}
}

.header-container {
	margin-block-end: 1rlh;
}