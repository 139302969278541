@use 'abstracts/variables' as *;
@use "sass:map";

body {
	background-color: var(--background);
	color: var(--text);
	accent-color: var(--accent);
	caret-color: var(--accent);
}

:focus-visible {
	outline: 2px solid var(--accent-secondary);
}

header#masthead {
	color: var(--text-emphasized);
	border-color: var(--accent);

	nav {

		a {
			&.active {
				color: var(--accent);
			}
		}
	}

	p {
		a {
			font-weight: var(--font-weight-semibold);
			color: var(--text-emphasized);

			&:hover {
				color: var(--accent);
			}

			&.secondary {
				color: var(--text-secondary);

				&:hover {
					color: var(--accent);
				}
			}
		}
	}
}

h1,
h2,
h3 {
	color: var(--text-emphasized);
}

h4,
h5 {
	color: var(--text);
}

h5 {
	color: var(--text-secondary);
}

h6 {
	color: var(--text-secondary);
}

a {
	color: inherit;
	text-decoration-color: var(--link-underline);

	transition-property: color, text-decoration-color;
	transition-duration: 0.15s;
	transition-timing-function: ease-in-out;

	&:hover {
		color: var(--accent);
		text-decoration-color: var(--accent);
	}
}

hr {
	background-color: var(--text-secondary);
}

ul.post-list,
ul.date-list {
	li {
		time {
			color: var(--text-secondary);
		}
	}
}

article {
	header {
		h1 {
			a {
				color: var(--text);
			}
		}

		.metadata {
			color: var(--text-secondary);
		}
	}

	.tags {
		margin-block-start: 1rlh;
		font-size: var(--step--1);
		line-height: 1rlh;
		color: var(--text-secondary);

		a.tag {
			text-decoration: none;
		}

	}

	&.long {
		section.content {

			// End mark
			>p:last-of-type::after {
				color: var(--accent);
			}
		}
	}

	.thanks {
		color: var(--text-secondary);
	}
}

details {
	summary {
		&::before {
			transform: rotate(0);
			background-color: var(--text-secondary);
			transition: .25s transform ease;
		}

		time {
			color: var(--text-secondary);
		}
	}
}

main~footer {
	border-color: var(--accent);
}


mark {
	// https://max.hn/thoughts/how-to-create-a-highlighter-marker-effect-in-css
	color: inherit;
	background: var(--highlight);
	margin: 0 -0.3em;
	border-radius: 0.7em 0.3em;
	padding: 0.15em 0.3em;

	-webkit-box-decoration-break: clone;
	box-decoration-break: clone;
}

blockquote {
	background: var(--background-secondary);
	border-color: var(--accent);
}

.changelog {
	a.commit {
		text-decoration: none;
		color: var(--accent);
	}
}

a.accent-underlined {
	text-decoration: underline;
	color: var(--accent);
}

#markdown-toc {
	&::before {
		color: var(--text);
	}

	color: var(--text-secondary);

	li {
		a {
			&:hover {
				color: var(--accent);
				text-decoration-color: var(--accent);
			}
		}
	}
}

figure {
	figcaption {
		color: var(--text-secondary);
	}
}

abbr {
	text-decoration-color: var(--link-underline);
}

main {
	h2.page-subtitle {
		color: var(--text-secondary);
	}
}


.tag-cloud {
	font-size: var(--step--1);
	color: var(--text-secondary);

	a {
		text-decoration: none;

		&::before {
			content: '#';
		}
	}
}