@use 'sass:math';
@use 'variables' as *;

@mixin dot-grid {
	background-size: 1rlh 1rlh;
	background-position-x: math.div(1rlh, 2);
	background-position-y: math.div(1rlh, 2);
	background-image: var(--gridlines);
}

@mixin fontVariations($mono: null, $casl: null, $wght: null, $slnt: null, $crsv: null) {
	$settings: (
	);

@if $mono !=null {
	$settings: append($settings, "MONO"#{$mono}, comma);
}

@if $casl !=null {
	$settings: append($settings, "CASL"#{$casl}, comma);
}

@if $wght !=null {
	$settings: append($settings, "wght"#{$wght}, comma);
}

@if $slnt !=null {
	$settings: append($settings, "slnt"#{$slnt}, comma);
}

@if $crsv !=null {
	$settings: append($settings, "CRSV"#{$crsv}, comma);
}

@if length($settings)>0 {
	font-variation-settings: $settings;
}
}

@mixin italicised($crsv: 1) {
	@include fontVariations($slnt: -15, $crsv: $crsv)
}

@mixin bounce-selection($duration: 0.2s, $scale_hover: 103%, $scale_active: 100%) {

	// transition: transform $duration ease-in-out;
	display: inline-block;

	transition: transform $duration ease-in-out,
		color $duration ease-in-out;

	&:hover,
	&:focus {
		transform: scale($scale_hover);
	}

	&:active {
		transform: scale($scale_active);
	}
}