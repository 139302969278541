@use 'abstracts/variables' as *;
@use 'abstracts/mixins' as *;

header#masthead {
	border-bottom: var(--border-thickness) solid;
	padding-block: 1rlh;

	.identity {
		display: grid;
		grid-template-columns: 1fr auto;
		align-items: center;

		.avatar {
			height: 2.5lh;
			width: auto;
			border-radius: 50%;
			margin: 0;
			transition: transform 0.2s ease-in-out;
		}
	}

	nav {
		text-transform: lowercase;
		margin-block-end: 1rlh;
		display: flex;
		justify-content: space-between;
		align-items: center;

		a {
			font-weight: var(--font-weight-semibold);
			text-decoration: none;
		}
	}

	@keyframes pulse {

		0%,
		100% {
			@include fontVariations($casl: 1, $wght: var(--font-weight-black), $slnt: var(--name-slant), $crsv: 1);

		}

		50% {
			@include fontVariations($casl: 1, $wght: var(--font-weight-bold), $slnt: var(--name-slant), $crsv: 1);
		}
	}

	h1 {
		margin-block: 0;

		a {
			text-decoration: none;

			transition: font-variation-settings 0.45s ease-in-out;

			&:hover {
				animation: pulse 0.9s ease-in-out infinite;
			}
		}
	}

	p {
		// text-wrap: pretty;
		line-height: 1lh;

		a>i {
			display: inline-block;
			min-width: 0.8em;
		}

		a {
			@include bounce-selection();
			text-decoration: none;

			&.secondary {
				font-size: var(--step--2);
				line-height: 1;

				@include italicised();

				i {
					min-width: 1.2em;
				}
			}

		}

	}

	.buttons {
		display: flex;
		flex-wrap: wrap;

		a {
			margin-top: 1lh;
		}
	}
}