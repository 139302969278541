@use "abstracts/variables" as *;

main~footer {
  font-size: var(--step--1);

  line-height: 1rlh;

  padding-block: 1rlh;
  border-top: var(--border-thickness) solid;
  color: var(--text-secondary);

  text-transform: lowercase;

  i {
    display: inline-block;
    width: 1em;
  }

  .textual {
    font-size: var(--step--2);

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .controls {
    font-size: var(--step--1);
  }

  .initials {
    display: block;
    font-size: var(--step-1);
    line-height: 1rlh;
  }

  p {
    a {
      text-decoration: none;
    }
  }

  .switch {
    display: inline-flex;
    align-items: center;
  }

  .links {
    grid-column: span 2; // Makes the links section span across two columns
  }

  .links nav {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .links a {
    text-decoration: none;
    justify-self: start; // Aligns individual links to the left of their grid cells
  }

  .social {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {}
  }

  .footer-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    row-gap: 1lh;
  }

  .col-1-3-row-1 {
    display: grid;
    grid-column: 1 / 4;
    grid-template-columns: repeat(2, 1fr);
    justify-content: start;
  }

  .col-4-6-row-1 {
    grid-column: 4 / 7;
  }

  .col-1-6-row-2 {
    display: flex;
    justify-content: space-between;
    grid-column: 1 / 7;
    grid-row: 2;
  }

  .col-1-5-row-3 {
    grid-column: 1 / 6;
    grid-row: 3;
  }

  .col-6-row-3 {
    grid-column: 6 / 7;
    grid-row: 3;

    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .col-3-4 a {
    text-align: left;
    display: block;
  }

  ::placeholder {
    /* Universal selector for all browsers */
    color: var(--accent-secondary);
  }

  .embeddable-buttondown-form {
    label {
      display: block;
      text-align: end; // Aligns text inside the button right
      font-weight: var(--font-weight-semibold);
    }

    input[type="email"] {
      &:focus {
        outline: 2px solid var(--accent-secondary);
        /* Red focus ring */
      }

      background: none;
      border: none; //var(--border-thickness) solid var(--link-underline);
      border-radius: 0.25em;
      display: block;
      // text-align: end;
      height: 0.8lh;
      padding: 0;
      margin-inline: 0;
      margin-block: 0.2em;
      width: 9em;
      margin-left: auto; // Aligns text inside the button right
    }

    button[type="submit"],
    input[type="submit"] {
      margin-left: auto; // Aligns text inside the button right
      width: 9em;

      display: block;
    }
  }
}