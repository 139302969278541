@use 'abstracts/variables' as *;

$switch-width: 1.3rlh;
$switch-height: 0.8rlh;
$knob-size: 0.6rlh;
$knob-position: 0.1rlh;
$knob-active-movement: 0.1rlh;

$transition-speed: .2s;
$box-shadow: inset 0 0 5px rgba(0, 0, 0, .2);
$knob-box-shadow: 0 1px 3px rgba(0, 0, 0, .2);
$bg-color-off-light: #c6c6c6;
$bg-color-on-light: var(--accent);
$bg-color-off-dark: #555;
$bg-color-on-dark: var(--accent);
$bg-color-active-light: #bbb;
$bg-color-active-dark: #777;

.toggle {
	margin-bottom: 0 !important; // Override vertical-rhythm-reset.

	position: relative;
	display: inline-block;
	width: $switch-width;
	height: $switch-height;
	appearance: none;
	outline: none;
	background-color: $bg-color-off-light;
	border-radius: $switch-height;
	box-shadow: $box-shadow;
	transition: background-color $transition-speed;

	@media (prefers-color-scheme: dark) {
		background-color: $bg-color-off-dark;
	}

	&:after {
		content: "";
		position: absolute;
		top: $knob-position;
		left: $knob-position;
		width: $knob-size;
		height: $knob-size;
		border-radius: 50%;
		background-color: #fff;
		box-shadow: $knob-box-shadow;
		transition: all $transition-speed;
	}

	&:active {
		background-color: $bg-color-active-light;

		&:after {
			transform: translatex($knob-active-movement);
		}

		@media (prefers-color-scheme: dark) {
			background-color: $bg-color-active-dark;
		}
	}

	&:checked {
		background-color: $bg-color-on-light;

		@media (prefers-color-scheme: dark) {
			background-color: $bg-color-on-dark;
		}

		$knob-offset: $switch-width - $knob-size - $knob-position * 2;

		&:after {
			transform: translatex($knob-offset);
		}

		&:active {
			&:after {
				transform: translatex($knob-offset - $knob-active-movement);
			}
		}
	}
}

.switch {
	label {
		margin-inline-end: var(--step--3);
	}
}