@use 'abstracts/mixins' as *;

img {
	max-width: 100%;
	block-size: auto;
	margin-block: 1rlh;

	border-radius: 0.5em;

	&.inline {
		display: inline;
		border-radius: none;

		margin-block: 0;
		margin-inline: auto;
		height: auto;
		max-height: 1lh;
		vertical-align: middle;
	}

	&.crop-top {
		object-position: 50% 10%;
	}

	&.crop-middle {
		object-position: 50% 50%;
	}

	&.crop-bottom {
		object-position: 50% 90%;
	}
}

figure {
	margin-block: 1lh;

	// Centered figures mostly for the purposes of audio rn.
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;

	img {
		margin-block: 0;
	}

	figcaption {
		@include italicised();

		display: block;
		text-align: center;
		line-height: 1lh;
		color: var(--text-secondary);
		font-size: var(--step--1);
	}

}

caption {
	padding-block-end: 1rlh;
	text-align: center;
	font-weight: var(--font-weight-semibold);
}

main {
	&img {
		max-width: 108%;
		margin-inline: -4%;

		// max-height: 14rlh; TODO: Force a certain number of lines. Perhaps it should differ on desktop / mobile. Maybe it should have utility classes that give me options.
		//object-fit: cover;
	}
}

audio {
	width: 100%;
}

.top-margin {
	margin-block-start: 1lh;
}

iframe {
	border: none;
}

.video-wrapper {
	margin-block: 1lh;

	position: relative;
	width: 100%;
	aspect-ratio: 16/9;
}

.video-wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.center {
	margin-inline: auto;
}

// 
// table {
// }
// 
// th,
// td {
// 	border: 1px solid var(--link-underline);
// 	padding: 0.5lh;
// 	text-align: left;
// }
// 
// th {
// 	background: var(--background-secondary);
// }


table {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
}


thead,
tfoot {
	background: var(--background-secondary);
}

tbody {}

th,
td {
	border: 1px solid var(--link-underline);
	padding: 0.5lh;
	text-align: left;
}


table.borderless {

	thead,
	tfoot {
		background: unset;
	}

	tbody {}

	th,
	td {
		border: none;
		padding-inline-start: 0;
		vertical-align: top;
	}

}

.wrap {
	white-space: normal;
}

.nowrap {
	white-space: nowrap;
}

i {
	&.fa-sm {
		display: inline-block;
		min-width: 0.825em;
	}
}