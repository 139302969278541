article:not(:last-child) {
	/* Add a 1-rem margin at the bottom */
	margin-bottom: 2rlh;
}

.pagination {
	margin-block-start: 1rlh;

	display: flex;
	justify-content: space-between;

	a {
		color: var(--text-secondary);
	}
}