@use 'abstracts/mixins' as *;

article {
	header {
		h1 {
			margin-block: 0; // Post title don’t need a top margin because of the time.

			a {
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.metadata {
			font-size: var(--step--1);
			line-height: 1rlh;

			a {
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}


			time {
				a {
					font-weight: inherit;
				}
			}

			span.via {
				@include italicised();

				p {
					display: inline;
				}
			}
		}
	}


	&.link {
		header {
			h1 {
				font-size: var(--step-1);
				line-height: 1lh;
				a {
					color: var(--accent);
				}
			}
		}
	}

	&.feedback {
		header {
			h1 {
				@include fontVariations($casl: 0, $crsv: 0);
				font-size: var(--step-0);
				text-wrap: wrap;
			}
		}

		h2 {
			@include fontVariations($casl: 0, $crsv: 0);
			font-size: var(--step-0);
			color: var(--text-secondary);
		}

		h3 {
			@include fontVariations($casl: 0, $wght: var(--font-weight-semibold), $crsv: 0);
			font-size: unset;
			margin-block-end: -1lh;
		}
	}

	// Long articles get endmarks.
	&.long {
		section.content {
			>p:last-of-type::after {
				content: " ▲";
			}
		}
	}

	section.content {
		p {
			hyphens: auto;
		}
	}

	.thanks {
		@include italicised();
		font-size: var(--step--1);
	}

	.read-more {}

	details.narration {
		i {
			display: inline-block;
			width: 1em;
		}

		summary {
			color: var(--text-secondary);
			font-size: var(--step--1);
		}
	}
}