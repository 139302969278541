:root {
  color-scheme: light dark;

  --name-slant: -10;

  --text-font-stack: 'Recursive', system-ui, sans-serif;
  --border-thickness: 2px;

  --light-background: oklch(98.99% 0.006 8.36);
  --dark-background: oklch(8% 0.026 10.56);
  --background: light-dark(var(--light-background), var(--dark-background));

  --light-background-secondary: oklch(60.72% 0.267 23.1 / 10%);
  --dark-background-secondary: oklch(65.11% 0.224 14.62 / 10%);
  --background-secondary: light-dark(var(--light-background-secondary), var(--dark-background-secondary));

  --light-background-tertiary: oklch(96.87% 0.006 4.64);
  --dark-background-tertiary: oklch(18.07% 0.001 15.94);
  --background-tertiary: light-dark(var(--light-background-secondary), var(--dark-background-secondary));


  --light-text: oklch(26.4% 0.108 19.88);
  --dark-text: oklch(94% 0.034 9.54);
  --text: light-dark(var(--light-text), var(--dark-text));

  --light-text-secondary: oklch(31.85% 0.134 21.05 / 70%);
  --dark-text-secondary: oklch(94% 0.034 9.54 / 70%);
  --text-secondary: light-dark(var(--light-text-secondary), var(--dark-text-secondary));

  --light-text--emphasized: oklch(10.08% 0.033 10.56);
  --dark-text--emphasized: oklch(98.99% 0.006 8.36);
  --text-emphasized: light-dark(var(--light-text--emphasized), var(--dark-text--emphasized));

  --light-accent: oklch(60.72% 0.267 23.1);
  --dark-accent: oklch(65.11% 0.224 14.62);
  --accent: light-dark(var(--light-accent), var(--dark-accent));

  --light-accent-secondary: oklch(60.72% 0.267 23.1 / 50%);
  --dark-accent-secondary: oklch(65.11% 0.224 14.62 / 50%);
  --accent-secondary: light-dark(var(--light-accent-secondary), var(--dark-accent-tertiary));

  --light-accent-tertiary: oklch(60.72% 0.267 23.1 / 12.5%);
  --dark-accent-tertiary: oklch(65.11% 0.224 14.62 / 12.5%);
  --accent-tertiary: light-dark(var(--light-accent-tertiary), var(--dark-accent-tertiary));

  --light-link-underline: oklch(60.72% 0.267 23.1 / 40%);
  --dark-link-underline: oklch(65.11% 0.224 14.62 / 50%);
  --link-underline: light-dark(var(--light-link-underline), var(--dark-link-underline));

  --light-highlight: oklch(60.72% 0.267 23.1 / 20%);
  --dark-highlight: oklch(65.11% 0.224 14.62 / 60%);
  --highlight: light-dark(var(--light-highlight), var(--dark-highlight));

  --light-gridlines: oklch(60.72% 0.267 23.1 / 25%);
  --dark-gridlines: oklch(60.72% 0.267 23.1 / 35%);
  --gridlines-color: light-dark(var(--light-gridlines), var(--dark-gridlines));

  --gridlines: radial-gradient(var(--gridlines-color), 1px, transparent 1px);
}

/* Can be removed once [light-dark()](https://developer.mozilla.org/en-US/docs/Web/CSS/color_value/light-dark) is supported in Safari desktop. */

@media (prefers-color-scheme: light) {
  :root {
    --background: var(--light-background);
    --background-secondary: var(--light-background-secondary);
    --background-tertiary: var(--light-background-tertiary);
    --text: var(--light-text);
    --text-secondary: var(--light-text-secondary);
    --text-emphasized: var(--light-text--emphasized);
    --accent: var(--light-accent);
    --accent-secondary: var(--light-accent-secondary);
    --accent-tertiary: var(--light-accent-tertiary);
    --link-underline: var(--light-link-underline);
    --highlight: var(--light-highlight);
    --gridlines-color: var(--light-gridlines);
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: var(--dark-background);
    --background-secondary: var(--dark-background-secondary);
    --background-tertiary: var(--dark-background-tertiary);
    --text: var(--dark-text);
    --text-secondary: var(--dark-text-secondary);
    --text-emphasized: var(--dark-text--emphasized);
    --accent: var(--dark-accent);
    --accent-secondary: var(--dark-accent-secondary);
    --accent-tertiary: var(--dark-accent-tertiary);
    --link-underline: var(--dark-link-underline);
    --highlight: var(--dark-highlight);
    --gridlines-color: var(--dark-gridlines);
  }
}