.changelog {
	.summary {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.time-description {
		flex-grow: 1;
		display: flex;
		align-items: flex-start;
		margin-inline-end: 0.5rlh;
	}

	time {
		margin-inline-end: 0.5rlh;
		white-space: nowrap;
	}

	.commit {
		margin-inline-start: 0.5rlh;

		display: flex;
		flex-shrink: 0;
		align-items: flex-start;

		i {
			height: 0.875em; //fa-sm
		}
	}
}