@use 'sass:math';

.nav-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    &:not(:last-child) {
        margin-inline-end: 0.5rlh;
    }
}

.nav-icon {
    height: 1lh;
    width: 1lh;
    text-align: center;
}

.nav-title {
    display: inline-block;
    text-align: center;
    margin-inline-start: 0.3em;
}