@use 'abstracts/variables' as *;
@use 'abstracts/mixins' as *;

.initials {
	@include fontVariations($casl: 1,
		$slnt: var(--name-slant),
		$crsv: 1,
		$wght: var(--font-weight-black))
}

.full-name {
	font-weight: var(--font-weight-bold);

	@include fontVariations($casl: 1,
		$slnt: var(--name-slant),
		$crsv: 1,
		$wght: var(--font-weight-bold))
}