@use 'abstracts/mixins';

body {
	display: grid;
	grid-template-columns: minmax(0, 1fr); // https://css-tricks.com/preventing-a-grid-blowout/
	grid-template-rows: repeat(3, auto);
	gap: 1rlh;

	max-width: calc(1rlh * 25);

	margin: 0 auto;
	padding-inline: 1rlh;

	// Debugging GRID
	// background-image: linear-gradient(rgba(50, 50, 50, 0.2) 1px, transparent 1px);
	// background-size: 100% 1rlh;
}

#markdown-toc {
	&::before {
		content: '§';
		font-weight: var(--font-weight-semibold);
	}

	font-size: var(--step--1);
	line-height: 1rlh;
	list-style-type: none;

	@media (min-width: 72em) {
		// position: sticky;
		top: 1rlh;
		float: left;
		margin-inline-start: -8rlh;
		max-width: 6rlh;
		padding-inline-start: 1lh;
	}

	ul {
		padding-inline-start: 0.5em;
		list-style-type: none;
	}

	li {
		a {
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

body:has(input[id="toggle-grid"]:checked) {
	@include mixins.dot-grid;
}

// Make headers one level smaller when they’re in the `main`.
main {
	min-height: 10rlh;

	h1 {
		font-size: var(--step-3);
		margin-block: 0; // page titles don’t need margins
	}

	h2 {
		font-size: var(--step-2);
		line-height: 1rlh;
		margin-block: 1rlh;

	}

	h3 {
		font-size: var(--step-1);
		line-height: 1rlh;

	}

	h4,
	h5,
	h6 {
		font-size: var(--s0);
		line-height: 1rlh;

	}

	p {
		margin-block: 1lh;
	}
}

ul.post-list,
ul.date-list {
	li {
		align-items: flex-start;
		display: flex;
		justify-content: space-between;

		time {
			font-size: var(--step--1);
			line-height: 1rlh;

			margin-inline-start: 0.5rlh;

			flex-shrink: 0;
			/* this ensures that the time will not shrink */
			white-space: nowrap;
			font-variant-numeric: tabular-nums;
		}
	}
}